import { fetchCategory } from "@/api";
import { ICategory } from "@/lib/interfaces/ICategory";
import { inter, poppins } from "@/styles/font";
import { Button, Flex, Grid, HStack, Text, useBoolean } from "@chakra-ui/react";
import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import Loader from "../loader/Loader";
import CategoryItemSM from "../category-item/CategoryItemSM";

export const CategorySM = () => {
    const [flag, setFlag] = useBoolean();

    const {
        isLoading,
        isSuccess,
        isError,
        error,
        data: allCategory,
    } = useQuery<ICategory[], AxiosError>("all-categories", fetchCategory, {
        useErrorBoundary: true,
    });

    if (isError) return <Text>An error occurred</Text>

    return (
        <Flex w={"full"} flexDirection={"column"}>
            <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                backgroundColor={"hsla(265, 15%, 85%, 1)"}
                p={3}
                w={"full"}
                roundedTopLeft={"md"}
                roundedTopRight={"md"}
            >
                <Text
                    className={poppins.className}
                    fontWeight={"semibold"}
                    color={"martlinegray.900"}
                >
                    Categories
                </Text>
                <Button
                    variant={"ghost"}
                    color={"martlinegray.600"}
                    className={inter.className}
                    fontSize={"12px"}
                    fontWeight={"medium"}
                    iconSpacing={"2px"}
                    display={"inline-flex"}
                    size={"sm"}
                    rightIcon={flag ? <CaretDown size={16} /> : <CaretRight size={16} />}
                    _active={{ backgroundColor: "martlineorange.100" }}
                    _focus={{ backgroundColor: "martlineorange.100" }}
                    onClick={setFlag.toggle}
                >
                    See All
                </Button>
            </HStack>

            {isLoading && <Loader />}

            {isSuccess && <Grid
                gridTemplateColumns={"repeat(3, 1fr)"}
                gridAutoRows={"1fr"}
                gap={6}
                p={4}
                boxShadow={"md"}
                backgroundColor={"white"}
                overflow={"hidden"}
                transition={"all .2s"}
            >
                {flag ? allCategory.map((item, index) => {
                    return (<CategoryItemSM
                        key={item._id}
                        catId={item._id}
                        image={item.icon}
                        title={item.title}
                        onClick={() => { }}
                    />)
                }) : allCategory.map((item, index) => {
                    if (index > 5) return;
                    return (<CategoryItemSM
                        key={item._id}
                        catId={item._id}
                        image={item.icon}
                        title={item.title}
                        onClick={() => { }}
                    />)
                })}
            </Grid>}
        </Flex>
    );
};

export default CategorySM;