import { poppins } from "@/styles/font";
import { CategoryItemType } from "@/types/CategoryItemType";
import { Text, VStack } from "@chakra-ui/react";
import Image from "next/image";
import { useRouter } from "next/router";
import logoBag from "../../../public/logo-bag.png";

const CategoryItemSM = ({ catId, image, title, onClick }: CategoryItemType) => {
    const router = useRouter();
  
    return (
      <VStack
        alignItems={"center"}
        justifyContent={"center"}
        px={2}
        py={4}
        rounded={"md"}
        boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
        onClick={() => {
          if (onClick) onClick();
          router.push({
            pathname: "/catalog/[categoryId]",
            query: { categoryId: catId, category: title.toLowerCase().replaceAll(" ", "-") },
          })
        }}
      >
        <Image src={image.startsWith("https://") ? image : logoBag} alt={''} width={22} height={22} />
        <Text
          textAlign={"center"}
          className={poppins.className}
          fontSize={"10px"}
          fontWeight={"medium"}
          color={"martlinegray.900"}
        >
          {title}
        </Text>
      </VStack>
    );
  };

  export default CategoryItemSM;