import { Box, CircularProgress } from "@chakra-ui/react";

const Loader = ({position} : {position?: string}) => {
  return (
    <Box
      display={"flex"}
      justifyContent={position===undefined ? "center" : position}
      alignItems={position===undefined ? "center" : position}
      w={"full"}
      h={"full"}
    >
      <CircularProgress
        isIndeterminate
        color={"martlineorange.500"}
        size={"35px"}
      />
    </Box>
  );
};

export default Loader;
